import React, { useEffect, useRef } from 'react';
import { Box, Typography, styled } from '@mui/material';
import LoginForm from '@features/login-form/index.login-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useBoundStore } from '@shared/store/useBoundStore';

const Wrapper = styled(Box)`
	background: none;
	margin-top: 20px !important;
	padding: 0 24px 24px 18px;
`;

const Spacer = styled('div')`
	margin-top: 16px;
`;

const Login = () => {
	const { error, createUserError } = useBoundStore((state) => ({
		error: state.error,
		createUserError: state.createUserError
	}));

	// const [isSignUp, setIsSignUp] = useState(false);

	const toastFailedLogin = useRef<any>(null);
	const toastFailedUserCreate = useRef<any>(null);

	const failedLogin = (message: string) => {
		toastFailedLogin.current = toast(message, {
			type: 'error',
			autoClose: 5000,
			closeButton: false
		});
	};

	const failedCreate = (errorObject: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		const errorKeys = Object.keys(errorObject);
		const errKeysUI: any = errorKeys.map((item, index) => {
			const header = (
				<Typography color={'black'} fontWeight={'bold'} component={'h2'} key={index}>
					{item.replace('data.', '').toUpperCase()}
				</Typography>
			);
			const list = errorObject[item].map((listItem: any, listItemIndex: number) => {
				return (
					<li style={{ fontSize: '12px' }} key={listItemIndex}>
						{listItem.replace('data.', '')}
					</li>
				);
			});

			return (
				<>
					{header}
					{list}
				</>
			);
		});

		toastFailedUserCreate.current = toast(<>{errKeysUI}</>, {
			type: 'error',
			autoClose: 15000,
			closeButton: true
		});
	};

	useEffect(() => {
		if (error) {
			failedLogin(error?.response?.data?.errors?.[0].detail);
		}
	}, [error]);

	useEffect(() => {
		if (createUserError) {
			createUserError.data && failedCreate(createUserError.data);
		}
	}, [createUserError]);

	return (
		<>
			<Spacer />
			<Wrapper>
				<Typography component='h1' variant='h4' align='center' gutterBottom></Typography>
				<Typography component='h2' variant='body1' align='center'></Typography>
				<LoginForm />
			</Wrapper>
		</>
	);
};

export default Login;
