import { Search } from '@mui/icons-material';
import { Button, Card, CardContent, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { StyledTextInputWrapper } from '@shared/components/styled-text-input-wrapper/index.styled-text-input-wrapper';
import { useBoundStore } from '@shared/store/useBoundStore';
import { ActionDiscover } from '@widgets/action-discover/index.action-discover';
import React, { useState } from 'react';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { Action } from '@shared/models/types';
export const ActionBrowserSearch = ({ actions }: { actions: Action[] }) => {
	const { setAction, resetActionState, setSelectedTab } = useBoundStore((state) => ({
		resetActionState: state.ceActionActions.resetActionState,
		setAction: state.ceActionActions.setAction,
		setSelectedTab: state.tabRouterActions.setSelectedTab
	}));

	const [filteredActions, setFilteredActions] = useState(actions);
	const [openedAction, setOpenedAction] = useState<Action | null>(null);
	const openAction = (action: Action) => {
		setOpenedAction(action);
	};

	return (
		<Card sx={{ background: 'none', boxShadow: 'none', overflow: 'visible' }}>
			<CardContent>
				{openedAction ? (
					<>
						<Button
							sx={{ marginBottom: '8px' }}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='text'
							onClick={() => {
								setFilteredActions(actions);
								setOpenedAction(null);
							}}>
							Back
						</Button>
						<Stack direction='column'>
							<Typography fontFamily='nHaas' variant='body1' fontSize='16px' lineHeight='24px'>
								{openedAction.name}
							</Typography>
							<Typography sx={{ opacity: '.6', fontSize: '14px' }}> {openedAction.service_uri}</Typography>
							<Typography sx={{ opacity: '.6', fontSize: '14px', marginTop: '16px' }}>
								{openedAction.description}
							</Typography>
							<Button
								sx={{ width: '155px', marginTop: '16px' }}
								variant='contained'
								onClick={() => {
									resetActionState();
									setAction(openedAction);
									setSelectedTab('action-execution');
								}}
								startIcon={<DashboardCustomizeOutlinedIcon />}>
								Use This Action
							</Button>
						</Stack>
					</>
				) : (
					<>
						<StyledTextInputWrapper>
							<TextField
								fullWidth
								onChange={(_e) => {
									if (!_e.target.value) {
										setFilteredActions(actions);
									}

									setFilteredActions(actions.filter((action: any) => action.name.includes(_e.target.value)));
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Search />
										</InputAdornment>
									)
								}}
								placeholder={'Search'}
								label={'Search'}
								type='text'
								variant='filled'
							/>
						</StyledTextInputWrapper>
						<div style={{ marginBottom: '8px' }} />

						<ActionDiscover openAction={openAction} actions={filteredActions} />
					</>
				)}
			</CardContent>
		</Card>
	);
};
