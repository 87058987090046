import { Button, IconButton, styled } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';

export const StyledSelect = styled('div')`
	.MuiFormControl-root [class*='MuiFormControl-root'] {
		margin: 0;
		& > div:first-of-type {
			margin-top: 3px;
			padding-left: 8px;
			text-align: center;
		}
	}
`;

export const StyledActionIconButton = styled(IconButton)`
	border-radius: 0;
	height: 100%;
	'& svg path': {
		fill: #9e9e9e;
	}
`;

// eslint-disable-next-line
export const FilterButtonCustomized = styled(Button)(({ theme }) => ({
	width: '50%',
	height: '35px',
	color: '#000 !important',
	backgroundColor: '#E0E0E0',
	border: 'none !important',
	'&.Mui-selected': { borderTop: 0, background: '#BDBDBD !important' }
}));

export const CustomCheckMark = styled(CheckRounded)(() => ({
	marginRight: '8px',
	fontSize: '20px'
}));
