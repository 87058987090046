/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { type StateCreator } from 'zustand';
import { AuthSliceInterface, type TabRouterSliceInterface } from './store-types';

export const createTabRouterSlice: StateCreator<
	TabRouterSliceInterface & AuthSliceInterface,
	[['zustand/immer', never], ['zustand/devtools', never]],
	[],
	TabRouterSliceInterface
> = (set) => ({
	selectedTab: 'login',
	tabSubtitle: '',
	tabRouterActions: {
		setSelectedTab: (newTab: string) => {
			set(() => ({ selectedTab: newTab }));
		},
		setTabSubtitle: (tabSubTitle: string) => {
			set(() => ({ tabSubTitle }));
		}
	}
});
