import { useBoundStore } from '@shared/store/useBoundStore';
import { useEffect } from 'react';

export const useExcelHandler = () => {
	const { resetActionState, setSelectedCell, setSelectedCellActionState, resetExcelState } = useBoundStore((state) => ({
		action: state.action,
		resetActionState: state.ceActionActions.resetActionState,
		selectedCell: state.selectedCell,
		setSelectedCell: state.excelActions.setSelectedCell,
		setSelectedCellActionState: state.excelActions.setSelectedCellActionState,
		resetExcelState: state.excelActions.resetExcelState
	}));

	useEffect(() => {
		// eslint-disable-next-line
		Office?.onReady(() => {
			// If needed, Office.js is ready to be called.
			// eslint-disable-next-line
			Office.context?.document?.addHandlerAsync?.(Office.EventType.DocumentSelectionChanged, function (eventArgs: any) {
				// eslint-disable-next-line
				Excel.run(async function (context) {
					resetExcelState();
					// eslint-disable-next-line
					const settings = context.workbook.settings.load();
					const range = context.workbook.getSelectedRange();
					range.load(['values', 'address']);

					return context.sync().then(function () {
						const rangeValue = range?.values?.[0]?.[0];
						// eslint-disable-next-line
						const functionMeta = Office.context.document.settings.get(`${range.address}`);
						if (functionMeta) {
							const parsedMeta = JSON.parse(functionMeta);
							if (parsedMeta?.jsonPayload?.displayValue === rangeValue) {
								setSelectedCellActionState(parsedMeta.jsonPayload);
							} else {
								// eslint-disable-next-line
								Office.context.document.settings.remove(`${range.address}`);
								// eslint-disable-next-line
								Office.context.document.settings.saveAsync();
							}
						}

						resetActionState();
						setSelectedCell(range?.address);
					});
				});
			});
		});
	}, []);

	// useEffect(() => {
	// 	// eslint-disable-next-line
	// 	Office?.onReady(() => {
	// 		// eslint-disable-next-line
	// 		Excel.run(async (context: any) => {
	// 			console.log('triggered');
	// 			const range = context.workbook.getSelectedRange();
	// 			range.load(['values', 'address']);
	// 			await context.sync();
	// 			setSelectedCell(range?.address);
	// 		});
	// 	});
	// }, []);

	// eslint-disable-next-line
	const onWorksheetChanged = async (_event: any) => {
		// TODO: something here maybe?
		// console.log(_event);
		if (_event.address && _event.changeType === 'RangeEdited') {
			// const { details, address } = _event;
		}
		// eslint-disable-next-line
		await Excel.run(async (context) => {
			const worksheets = context.workbook.worksheets;
			worksheets.load(['items', 'name']);
			await context.sync();
			worksheets.items.forEach((sheet) => {
				sheet.load('name');
			});
		});
	};

	useEffect(() => {
		// eslint-disable-next-line
		Office?.onReady(() => {
			// eslint-disable-next-line
			Excel &&
				// eslint-disable-next-line
				Excel?.run &&
				// eslint-disable-next-line
				Excel.run(async (context: any) => {
					let sheets = context.workbook.worksheets;
					sheets.load('items/name/id');
					await context.sync();
					// console.log('sheets', sheets);
					// sheets.items.length && sheets.items.forEach((sheet: any) => console.log(sheet.id));
					const workbook = context.workbook;
					workbook.worksheets.onChanged.add(onWorksheetChanged);
					workbook.worksheets.onAdded.add(onWorksheetChanged);
					await context.sync();
				});
		});
	}, []);
};
