import React from 'react';
import { WithAuth } from '../providers/with-auth';
import { WithTheme } from '../providers/with-theme';
import { TabRouter } from '@features/tab-router/index.tab-router';
import { Footer } from '@entities/footer/index.footer';
import { useExcelHandler } from '@shared/hooks/useExcelHandler';
import { TaskpaneContent } from '@widgets/taskpane-content/index.taskpane-content';

const App = () => {
	useExcelHandler();

	return (
		<div style={{ height: '100%', background: '#F5F5F5' }}>
			<WithAuth>
				<WithTheme>
					<TabRouter />
					<TaskpaneContent />
					<Footer />
				</WithTheme>
			</WithAuth>
		</div>
	);
};

export default App;
