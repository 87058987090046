/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { type StateCreator } from 'zustand';
import { type CEActionSliceInterface, type ExcelSliceInterface } from './store-types';

export const createExcelSlice: StateCreator<
	ExcelSliceInterface & CEActionSliceInterface,
	[['zustand/immer', never], ['zustand/devtools', never]],
	[],
	ExcelSliceInterface
> = (set) => ({
	selectedCell: null,
	cellActionState: null,
	excelActions: {
		setSelectedCell: (selectedCell) => {
			set(() => ({ selectedCell }));
		},
		setSelectedCellActionState: (cellActionState) => {
			set(() => ({ cellActionState }));
		},
		resetExcelState: () => {
			set(() => ({ selectedCell: null, cellActionState: null }));
		}
	}
});
