import React, { SyntheticEvent } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Tune } from '@mui/icons-material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useBoundStore } from '@shared/store/useBoundStore';
import SearchIcon from '@mui/icons-material/Search';

export const TabRouter = () => {
	const { isAuthenticated, selectedTab, setSelectedTab, tabSubtitle, setTabSubtitle } = useBoundStore((state) => ({
		selectedTab: state.selectedTab,
		isAuthenticated: state.isAuthenticated,
		tabSubtitle: state.tabSubtitle,
		setSelectedTab: state.tabRouterActions.setSelectedTab,
		setTabSubtitle: state.tabRouterActions.setTabSubtitle
	}));

	const handleSelectSettingsTab = (event: SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
		setTabSubtitle('');
	};

	return (
		<div style={{ padding: '0 16px' }}>
			<Box sx={{ width: '100%', padding: '0 0 16px' }}>
				<Tabs value={selectedTab} onChange={handleSelectSettingsTab}>
					<Tab disabled={!isAuthenticated} value='action-execution' icon={<Tune />} />
					<Tab disabled={!isAuthenticated} value='action-browser' icon={<SearchIcon />} />
					<Tab icon={<PersonOutlineIcon />} value='login' />
				</Tabs>
			</Box>
			<Typography fontFamily='nHaas' variant='body1' fontSize='16px' lineHeight='24px' paddingLeft='2px'>
				{selectedTab === 'login' ? 'Admin' : 'Actions'}
				{tabSubtitle !== '' ? `/${tabSubtitle}` : null}
			</Typography>
		</div>
	);
};
