import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Spinner from '@shared/components/spinner/index.spinner';

export const NotificationSpinner = ({ message }: any) => {
	return (
		<Stack alignItems='center' flexDirection={'row'}>
			<Box sx={{ position: 'relative', width: '30px', height: '30px' }}>
				<Spinner style={{ maxWidth: '100%', maxHeight: '100%', position: 'static' }} />
			</Box>
			<Typography sx={{ marginLeft: '10px' }}>{message || 'In progress...'}</Typography>
		</Stack>
	);
};
