import React, { useEffect } from 'react';
import useLocalTheme from '@shared/hooks/useLocalTheme';
import { useBoundStore } from '@shared/store/useBoundStore';
import { THEMES } from '@shared/utils/index';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createTheme from '@shared/theme';
import useTheme from '@shared/hooks/useTheme';

export function WithTheme({ children }: { children: any }) {
	const { setTheme } = useLocalTheme();
	const { theme } = useTheme();

	const { user } = useBoundStore((state) => ({
		user: state.user
	}));

	useEffect(() => {
		const themeTypes = {
			investor: THEMES.DEFAULT,
			corporate: THEMES.DEFAULT,
			admin: THEMES.DARK
		};

		if (user?.persona) {
			setTheme((themeTypes as any)[user.persona]);
		} else {
			setTheme(THEMES.DEFAULT);
		}
	}, [user]);

	return <MuiThemeProvider<ReturnType<typeof createTheme>> theme={createTheme(theme)}>{children}</MuiThemeProvider>;
}
