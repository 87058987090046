import { Search } from '@mui/icons-material';
import { Autocomplete, CircularProgress, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { useBoundStore } from '@shared/store/useBoundStore';
import ReadMoreSharpIcon from '@mui/icons-material/ReadMoreSharp';
import { useHandleFormPopulationFromExcelFormula } from '@shared/hooks/useHandleFormPopulationFromExcelFormula';
import { StyledTextInputWrapper } from '@shared/components/styled-text-input-wrapper/index.styled-text-input-wrapper';
import { Action } from '@shared/models/types';

export const ActionsList = ({ actions }: { actions: Action[] }) => {
	const { setAction, resetActionState, action, cellActionState } = useBoundStore((state) => ({
		resetActionState: state.ceActionActions.resetActionState,
		actionState: state.actionState,
		action: state.action,
		setAction: state.ceActionActions.setAction,
		cellActionState: state.cellActionState,
		updateAction: state.ceActionActions.updateAction
	}));

	const { getActionFromFormulaBar, handlerLoading } = useHandleFormPopulationFromExcelFormula();

	return (
		<Grid container alignItems='center'>
			<Grid sx={{ width: `${cellActionState ? 'calc(100% - 40px)' : '100%'}` }} item>
				<Autocomplete
					options={actions}
					id='ce-actions-list'
					disableClearable
					fullWidth
					value={action}
					getOptionLabel={(option: any) => option.name}
					onChange={(_event, selectedAction) => {
						resetActionState();
						setAction(selectedAction);
					}}
					renderInput={(params) => {
						return (
							<StyledTextInputWrapper>
								<TextField
									{...params}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<InputAdornment sx={{ marginTop: '-16px', marginRight: '-24px' }} position='end'>
												<Search />
											</InputAdornment>
										)
									}}
									placeholder={'Search'}
									label={'Search'}
									type='text'
									variant='filled'
								/>
							</StyledTextInputWrapper>
						);
					}}
				/>
			</Grid>
			{cellActionState && (
				<Grid item>
					<Tooltip title='Get form values from cell formula'>
						<IconButton color='primary' onClick={() => getActionFromFormulaBar()}>
							{handlerLoading ? <CircularProgress /> : <ReadMoreSharpIcon />}
						</IconButton>
					</Tooltip>
				</Grid>
			)}
		</Grid>
	);
};
