import { Card, CardContent } from '@mui/material';
import { useBoundStore } from '@shared/store/useBoundStore';
import { ActionBrowserSearch } from '@widgets/action-browser-search/index.action-browser-search';
import { ActionDescription } from '@widgets/action-description/index.action-description';
import ActionSettings from '@widgets/action-settings/index.action-settings';
import { useActionsList } from '@widgets/actions-list/queries/index.useActionsList';
import { ActionsList } from '@widgets/actions-list/ui/index.actions-list';
import { ErrorContainer } from '@widgets/error-container/index.error-container';
import Login from '@widgets/login/index.login';
import React from 'react';

export const TaskpaneContent = () => {
	const { isAuthenticated, selectedTab } = useBoundStore((state) => ({
		isAuthenticated: state.isAuthenticated,
		selectedTab: state.selectedTab,
		cellActionState: state.cellActionState
	}));

	const { actions } = useActionsList();

	const renderContent = (tab: null | string) => {
		if (!isAuthenticated || tab === 'login') {
			return <Login />;
		}

		switch (tab) {
			case 'login':
				return <Login />;
			case 'action-execution':
				return (
					<Card sx={{ background: 'none', boxShadow: 'none', overflow: 'visible' }}>
						<CardContent>
							<ErrorContainer />
							<ActionsList actions={actions} />
							<ActionDescription />
							<ActionSettings />
						</CardContent>
					</Card>
				);
			case 'action-browser':
				return <ActionBrowserSearch actions={actions} />;
			default:
				return <Login />;
		}
	};

	return <>{renderContent(selectedTab)}</>;
};
