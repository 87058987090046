import React, { useEffect, useState } from 'react';
import { clearErrors, getErrors } from '../../../utils/error-engine/index.error-engine';
import { Card, CardContent, CardHeader, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
const StyledErrorIcon = () => (
	<ListItemIcon sx={{ color: 'rgb(95, 33, 32)' }}>
		<PriorityHighIcon />
	</ListItemIcon>
);

export const ErrorContainer = () => {
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		clearErrors();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			const currentErrors = getErrors();
			if (currentErrors.length > 0) {
				setErrors(currentErrors);
			} else {
				setErrors([]);
				clearErrors();
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);
	const formatErrors = () => {
		return (
			<List>
				{errors.map((error: any, index: any) => {
					const {
						data: { raw, iterableErrors, request, openJSONSchemaErrors }
					} = error;
					if (openJSONSchemaErrors) {
						return openJSONSchemaErrors.map((openJSONschemaError: any) => {
							const { status, title, detail } = openJSONschemaError;

							return (
								<ListItem key={index}>
									<StyledErrorIcon />
									<ListItemText
										primary={`${status}: ${title || 'Uncaught Error'}`}
										secondary={`Cell: ${request.cell} - ${detail}`}
									/>
								</ListItem>
							);
						});
					}

					if (iterableErrors) {
						let serverErrors: any = Object.values(iterableErrors);

						return serverErrors.map((serverError: string) => (
							<ListItem key={index}>
								<StyledErrorIcon />
								<ListItemText primary={`${serverError}`} secondary={`Cell: ${request.cell}`} />
							</ListItem>
						));
					}

					return (
						<ListItem key={index}>
							{error && (
								<>
									<StyledErrorIcon />
									<ListItemText
										primary={`${raw?.meta?.message || 'Uncaught Error'}`}
										secondary={`Cell: ${request.cell} - Please check your network activity for more detail`}
									/>
								</>
							)}
						</ListItem>
					);
				})}
			</List>
		);
	};

	return (
		<>
			{errors.length > 0 && (
				<Card
					sx={{
						marginBottom: '16px',
						color: 'rgb(95, 33, 32)',
						backgroundColor: 'rgb(253, 237, 237)'
					}}>
					<CardHeader
						sx={{ paddingBottom: '8px' }}
						title={`Error`}
						avatar={<ErrorIcon sx={{ color: '#d32f2f' }} />}
						action={
							<IconButton
								onClick={() => {
									clearErrors();
									setErrors([]);
								}}>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent sx={{ paddingTop: 0, paddingBottom: '16px' }}>{formatErrors()}</CardContent>
				</Card>
			)}
		</>
	);
};
