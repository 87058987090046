import { create } from 'zustand';
import { createAuthSlice } from './auth-slice';
import { createActionSlice } from './action-slice';
import { createTabRouterSlice } from './tab-router-slice';
import { createExcelSlice } from './excel-slice';
import { devtools } from 'zustand/middleware';
import {
	type AuthSliceInterface,
	type CEActionSliceInterface,
	type ExcelSliceInterface,
	type TabRouterSliceInterface
} from './store-types';
import { immer } from 'zustand/middleware/immer';

export const useBoundStore = create<
	AuthSliceInterface & CEActionSliceInterface & TabRouterSliceInterface & ExcelSliceInterface
>()(
	immer(
		devtools((...a) => ({
			...createAuthSlice(...a),
			...createActionSlice(...a),
			...createTabRouterSlice(...a),
			...createExcelSlice(...a)
		}))
	)
);
