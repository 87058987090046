import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useBoundStore } from '@shared/store/useBoundStore';
export const ActionDiscover = ({ actions, openAction }: any) => {
	const { setTabSubtitle } = useBoundStore((state) => ({
		setTabSubtitle: state.tabRouterActions.setTabSubtitle
	}));

	return (
		actions && (
			<>
				{actions?.map?.((action: any, index: number) => (
					<Card
						key={index}
						sx={{ minHeight: '52px', padding: '6px 16px', marginBottom: '8px', cursor: 'pointer' }}
						onClick={() => {
							setTabSubtitle(action.name);
							openAction(action);
						}}>
						<Stack direction='row' alignContent='center' justifyContent='space-between' alignItems='center'>
							<Stack direction='column'>
								<Typography
									sx={{ textWrap: 'no-wrap' }}
									fontFamily='nHaas'
									variant='body1'
									fontSize='16px'
									lineHeight='24px'>
									{action.name}
								</Typography>
								<Typography sx={{ opacity: '.6', fontSize: '14px' }}>{action?.service_uri}</Typography>
							</Stack>
							<InfoOutlinedIcon sx={{ opacity: '.6' }} />
						</Stack>
					</Card>
				))}
			</>
		)
	);
};
