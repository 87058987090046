import api from '@shared/api/config';
import { httpRequest } from '@shared/api/http-request';
import { ActionResponse } from '@shared/models/types';
import { useBoundStore } from '@shared/store/useBoundStore';
import React, { useEffect, useState } from 'react';

/**
 * Returns future list of actions, using tiletypes for now to demonstrate
 */

export const useActionsList = () => {
	const { jtubesRoot } = api;
	const { isAuthenticated } = useBoundStore((state) => ({
		isAuthenticated: state.isAuthenticated
	}));

	const [actions, setActions] = useState([]);
	useEffect(() => {
		isAuthenticated &&
			httpRequest({
				method: 'get',
				url: `${jtubesRoot.url}/action`,
				resolveResponse: (response: any) => {
					const actionsResponse = response.map((resObject: ActionResponse) => resObject.attributes);
					setActions(actionsResponse);
				},
				handleError: (err) => console.error(err)
			});
	}, [isAuthenticated]);

	return { actions };
};
