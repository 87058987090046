import React, { ChangeEvent } from 'react';
import { Autocomplete, TextField, Typography, styled } from '@mui/material';
import { SelectDimension, SelectPanels } from '@entities/librarian-query-inputs';
import { useBoundStore } from '@shared/store/useBoundStore';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { SelectSegment } from '@entities/librarian-query-inputs/select-segment';
import { SelectSymbol } from '@entities/librarian-query-inputs/select-symbol';
import { StyledTextInputWrapper } from '@shared/components/styled-text-input-wrapper/index.styled-text-input-wrapper';
import { ActionParameter, DefaultFields, QueryStateArgument, QueryStatePanelArgument } from '@shared/models/types';

const StyledRangeWrapper = styled('div')`
	align-items: center;
	display: flex;
	> div {
		width: 100%;
	}
`;

const Spacer = styled('div')`
	margin-top: 16px;
`;

export const ActionSettings = () => {
	const { actionState, action, updateAction } = useBoundStore((state) => ({
		action: state.action,
		actionState: state.actionState,
		updateAction: state.ceActionActions.updateAction
	}));

	const handleQueryInput = ({ arg, queryDataKey }: { arg: QueryStateArgument; queryDataKey: string }) => {
		updateAction(arg, queryDataKey);
	};

	const renderFields = (fieldGroup: ActionParameter, index: number) => {
		let fieldDefaultsList: DefaultFields[] = [],
			locationSlug = null,
			restrictedToValuesList: any = [];
		if (fieldGroup.values.name) {
			const { name } = fieldGroup.values;
			locationSlug = name;
		}

		if (fieldGroup?.values?.values?.length > 0) {
			const fieldValues = fieldGroup.values.values;
			fieldValues.forEach((value: DefaultFields) => {
				const { label } = value;
				const filteredField = { value: value.slug || value.value || value.name, label };
				if (value.is_required) {
					fieldDefaultsList.push(filteredField);
				}

				restrictedToValuesList.push(filteredField);
			});
		}

		switch (fieldGroup.type) {
			case 'dimensions':
				return (
					<StyledRangeWrapper key={index}>
						<SubdirectoryArrowRightIcon />
						<SelectDimension
							fieldDefaultsList={fieldDefaultsList}
							restrictedToValuesList={restrictedToValuesList}
							parameterName={fieldGroup.name}
							label={fieldGroup.label}
							locationSlug={locationSlug || fieldGroup.location || fieldGroup.name}
							selectedFilters={actionState[fieldGroup.name]}
							fieldType={fieldGroup.type}
							handleQueryInput={handleQueryInput}
						/>
					</StyledRangeWrapper>
				);
			// TODO: simple inputs are... pretty generic, look for a common select instead...
			case 'panels':
				return (
					<StyledRangeWrapper key={index}>
						<SubdirectoryArrowRightIcon />
						<SelectPanels
							fieldDefaultsList={fieldDefaultsList}
							restrictedToValuesList={restrictedToValuesList}
							fieldType={fieldGroup.type}
							parameterName={fieldGroup.name}
							selectedPanels={actionState[fieldGroup.name] as QueryStatePanelArgument}
							handleQueryInput={handleQueryInput}
							parameterLabel={fieldGroup.label}
						/>
					</StyledRangeWrapper>
				);
			case 'segment':
				return (
					<StyledRangeWrapper key={index}>
						<SubdirectoryArrowRightIcon />
						<SelectSegment
							fieldDefaultsList={fieldDefaultsList}
							restrictedToValuesList={restrictedToValuesList}
							fieldType={fieldGroup.type}
							parameterName={fieldGroup.name}
							selectedSegments={actionState[fieldGroup.name]}
							handleQueryInput={handleQueryInput}
							parameterLabel={fieldGroup.label}
						/>
					</StyledRangeWrapper>
				);
			case 'symbol':
				return (
					<StyledRangeWrapper key={index}>
						<SubdirectoryArrowRightIcon />
						<SelectSymbol
							fieldDefaultsList={fieldDefaultsList}
							restrictedToValuesList={restrictedToValuesList}
							fieldType={fieldGroup.type}
							parameterName={fieldGroup.name}
							selectedSymbols={actionState[fieldGroup.name]}
							handleQueryInput={handleQueryInput}
							parameterLabel={fieldGroup.label}
						/>
					</StyledRangeWrapper>
				);
			case 'number':
				return (
					<StyledRangeWrapper>
						<SubdirectoryArrowRightIcon />
						<StyledTextInputWrapper>
							<TextField
								fullWidth
								variant='filled'
								type='number'
								label={fieldGroup.label}
								value={
									actionState[fieldGroup.name] &&
									(actionState[fieldGroup.name]?.[fieldGroup.name]?.values?.[0]?.value as any)
								}
								onChange={(_e: ChangeEvent<HTMLInputElement>) => {
									const {
										target: { value }
									} = _e;
									const valueObject: any = { value };
									let numberState: any = structuredClone(actionState[fieldGroup.name]);
									if (numberState) {
										numberState[fieldGroup.name].values = [valueObject];
									} else {
										numberState = {
											[fieldGroup.name]: { values: [valueObject] }
										};
									}

									handleQueryInput({ arg: numberState, queryDataKey: fieldGroup.name });
								}}
							/>
						</StyledTextInputWrapper>
					</StyledRangeWrapper>
				);
			case 'custom':
				return (
					<StyledRangeWrapper key={index}>
						<SubdirectoryArrowRightIcon />
						<Autocomplete
							multiple
							options={fieldGroup.values.values}
							id='ce-custom-list'
							disableClearable
							fullWidth
							value={(actionState?.[fieldGroup.name]?.[fieldGroup.name]?.values as any) || []}
							getOptionLabel={(option: { label: string }) => option.label}
							onChange={(_event, changedOptions) => {
								let customSelectState: any = structuredClone(actionState[fieldGroup.name]);
								if (customSelectState) {
									customSelectState[fieldGroup.name].values = changedOptions;
								} else {
									customSelectState = {
										[fieldGroup.name]: { values: changedOptions }
									};
								}

								handleQueryInput({ arg: customSelectState, queryDataKey: fieldGroup.name });
							}}
							renderInput={(params) => {
								return (
									<StyledTextInputWrapper>
										<TextField
											{...params}
											placeholder={
												actionState?.[fieldGroup.name]?.[fieldGroup.name]?.values?.length > 0 ? '' : fieldGroup.label
											}
											type='text'
											variant='filled'
											label={fieldGroup.label}
										/>
									</StyledTextInputWrapper>
								);
							}}
						/>
					</StyledRangeWrapper>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{action?.action_parameters?.length ? (
				action?.action_parameters.map((field: ActionParameter, index: number) => (
					<>
						<Spacer />
						{renderFields(field, index)}
					</>
				))
			) : (
				<>
					<Spacer />
					<Typography>Select an Action to get started...</Typography>
				</>
			)}
		</>
	);
};

export default ActionSettings;
