import React, { useEffect } from 'react';
import { Chip, Grid } from '@mui/material';
import Search from '@features/search/index.search';

const SelectDimension = ({
	parameterName,
	locationSlug,
	handleQueryInput,
	selectedFilters,
	label,
	disabled,
	fieldDefaultsList,
	restrictedToValuesList
}: any) => {
	const handleFilterChange = (option: any, optionKey: string) => {
		const filters = selectedFilters
			? {
				...selectedFilters,
				[optionKey]: { ...selectedFilters[optionKey], values: option, label }
			}
			: {
				[optionKey]: { values: option, label }
			};
		if (option && option.length === 0 && filters?.[optionKey] && !filters[optionKey].group) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete filters[optionKey as any];
		}

		let finalFilters = structuredClone(filters);
		// this is gonna be common isnt it lol
		if (fieldDefaultsList && fieldDefaultsList.length > 0) {
			const mergedMap = new Map(
				[...fieldDefaultsList, ...finalFilters[locationSlug].values].map((obj) => [obj.value, obj])
			);
			finalFilters[locationSlug].values = Array.from(mergedMap.values());
		}

		handleQueryInput({ arg: finalFilters, queryDataKey: parameterName });
	};

	const getDimensionValues = (name: string) => {
		let filters = selectedFilters?.[name]?.values || [];
		if (!filters.length && fieldDefaultsList.length > 0) {
			filters = fieldDefaultsList;
		}

		return filters;
	};

	const formatSearchResults = (response: any) => {
		if (response && response.length > 0) {
			return response.map((value: { slug: string; label: string }) => {
				return { value: value.slug, label: value.label };
			});
		}

		return response;
	};

	const renderTagsOverride = (tagValue: any, getTagProps: any) => {
		return tagValue.map((option: any, index: any, key: any) => {
			return (
				<Chip
					key={key}
					label={option.label}
					{...getTagProps({ index })}
					disabled={fieldDefaultsList.filter((defaultValue: any) => defaultValue?.value === option.value).length > 0}
				/>
			);
		});
	};

	const renderAutocomplete = () => {
		return (
			<Grid className='dimension-search-container' container spacing={2}>
				<Grid item xs={12}>
					<Search
						disableCloseOnSelect={true}
						isDisabled={disabled}
						customFormat={{
							'& fieldset': {
								borderRadius: '4px 0px 0px 0px',
								borderBottom: '2px'
							}
						}}
						params={{
							filters: {
								location_slug: [locationSlug],
								slug:
									restrictedToValuesList && restrictedToValuesList.length > 0
										? restrictedToValuesList.map(
											(field: { slug?: string; value?: string }) => field.slug || field.value
										)
										: null
							},
							exclusions: {}
						}}
						onSearchOptionSelect={handleFilterChange}
						expandedOnChangeArg={locationSlug}
						formatResponse={formatSearchResults}
						limit={20}
						optionLabel={(e: any) => e.label}
						renderTagsOverride={renderTagsOverride}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						savedValues={getDimensionValues(locationSlug)}
						searchPlaceholder={`${label}`}
						searchImmediately={true}
					/>
				</Grid>
			</Grid>
		);
	};

	return <div style={{ textAlign: 'center' }}>{renderAutocomplete()}</div>;
};

export default SelectDimension;
