import React from 'react';
import { Stack } from '@mui/material';
import Search from '@features/search/index.search';

export const SelectSegment = ({
	selectedSegments,
	handleQueryInput,
	parameterName,
	parameterLabel,
	fieldType
}: any) => {
	const setSelectedSegment = (values: any) => {
		let previousSelection = structuredClone(selectedSegments);
		if (previousSelection && previousSelection.length > 0) {
			previousSelection[fieldType].values = values;
		} else {
			previousSelection = {
				[fieldType]: { values }
			};
		}

		handleQueryInput({ arg: previousSelection, queryDataKey: parameterName });
	};

	return (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<Search
				params={{
					filters: {
						location_slug: ['segment']
					},
					exclusions: {}
				}}
				onSearchOptionSelect={(value: any) => {
					setSelectedSegment(value);
				}}
				savedValues={selectedSegments?.[fieldType]?.values ?? []}
				optionLabel={(e: any) => e.label}
				searchPlaceholder={parameterLabel}
				limit={20}
				searchImmediately={true}
			/>
		</Stack>
	);
};
