import React, { useEffect } from 'react';

import { THEMES } from '../../utils/index';

const initialState = {
  theme: THEMES.DEFAULT,
  // eslint-disable-next-line no-empty-function, prettier/prettier
  setTheme: (_value: any) => { }
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }: any) {
  const [theme, _setTheme] = React.useState(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const setTheme = (theme: any) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    _setTheme(theme);
  };

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}

export { ThemeProvider, ThemeContext };
