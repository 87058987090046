/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import useDebounce from '@shared/hooks/useDebounce';
import { StyledTextInputWrapper } from '@shared/components/styled-text-input-wrapper/index.styled-text-input-wrapper';
import api from '@shared/api/config';
import { httpRequest } from '@shared/api/http-request';

const Search = ({
	onSearchOptionSelect,
	params,
	savedValues,
	formatResponse,
	groupBy,
	optionLabel,
	isHeaderSearch = false,
	searchPlaceholder = 'Search',
	limit = 20,
	searchImmediately = false,
	expandedOnChangeArg,
	multiple = true,
	fullWidth = true,
	customFormat,
	isDisabled = false,
	customAdornment,
	disableCloseOnSelect = false,
	renderTagsOverride
}: {
	isHeaderSearch?: boolean;
	isDisabled?: boolean;
	onSearchOptionSelect?: any;
	params: any;
	savedValues?: any;
	formatResponse?: any;
	groupBy?: any;
	optionLabel?: any;
	searchPlaceholder?: any;
	limit?: any;
	searchImmediately?: boolean;
	expandedOnChangeArg?: any;
	multiple?: boolean;
	fullWidth?: boolean;
	customFormat?: any;
	customAdornment?: any;
	disableCloseOnSelect?: boolean;
	renderTagsOverride?: (arg: any, argAgain: any) => any;
}) => {
	const { jtubesRoot } = api;
	const [searchInput, setSearchInput] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	// SO! this is the brain of search - we expose some params in here that the user can chuck in. in here is where we pass in the filters/exclusionssss
	const searchQuery = (queryValue: string) => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		httpRequest<any>({
			method: 'post',
			url: `${jtubesRoot.url}/search`,
			hasNestedDataResponse: false,
			data: {
				data: {
					query: queryValue,
					...params,
					limit
				}
			},
			// eslint-disable-next-line no-empty-function
			handleError: () => { },
			resolveResponse: (response) => {
				formatResponse ? setSearchResults(formatResponse(response.data)) : setSearchResults(response.data);
			}
		});
	};

	const debouncedSearchTerm = useDebounce(searchInput, 300);

	useEffect(() => {
		// trigger for search on debounce
		(searchInput.length || searchImmediately) && searchQuery(searchInput);
	}, [debouncedSearchTerm]);

	// Just the mui automcomplete and most of the options are just exposed as props
	if (renderTagsOverride) {
		return (
			<Autocomplete
				disableCloseOnSelect={disableCloseOnSelect}
				disabled={isDisabled}
				sx={customFormat && { ...customFormat }}
				// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
				options={searchResults && searchResults.length ? searchResults : []}
				fullWidth={fullWidth}
				id='search-container'
				onChange={(e: any, value: any) => {
					// Clear search input for fresh search
					setSearchInput('');
					onSearchOptionSelect
						? onSearchOptionSelect(value, expandedOnChangeArg)
						: console.info('Search Values', value);
				}}
				forcePopupIcon={customAdornment === undefined}
				getOptionLabel={(option: any) => {
					return optionLabel ? optionLabel(option) : option.label;
				}}
				onInputChange={(e, value) => {
					value.length ? setSearchInput(value) : setSearchInput('');
				}}
				groupBy={(option) => {
					return groupBy ? groupBy(option) : option.location_label;
				}}
				value={savedValues}
				filterSelectedOptions
				multiple={multiple}
				filterOptions={(x) => x}
				renderTags={(tagValue, getTagProps) => {
					return renderTagsOverride(tagValue, getTagProps);
				}}
				renderInput={(params) => (
					<StyledTextInputWrapper>
						<TextField
							variant={isHeaderSearch ? 'outlined' : 'filled'}
							{...params}
							inputProps={{
								...params.inputProps,
								autoComplete: 'search',
								endAdornment: customAdornment || null
							}}
							id='search-field'
							label={searchPlaceholder}
							placeholder={savedValues && savedValues.length ? '' : searchPlaceholder}
						/>
					</StyledTextInputWrapper>
				)}
			/>
		);
	}

	return (
		<Autocomplete
			disableCloseOnSelect={disableCloseOnSelect}
			disabled={isDisabled}
			sx={customFormat && { ...customFormat }}
			// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
			options={searchResults && searchResults.length ? searchResults : []}
			fullWidth={fullWidth}
			id='search-container'
			onChange={(e: any, value: any) => {
				// Clear search input for fresh search
				setSearchInput('');
				onSearchOptionSelect ? onSearchOptionSelect(value, expandedOnChangeArg) : console.info('Search Values', value);
			}}
			forcePopupIcon={customAdornment === undefined}
			getOptionLabel={(option: any) => {
				return optionLabel ? optionLabel(option) : option.label;
			}}
			onInputChange={(e, value) => {
				value.length && setSearchInput(value);
			}}
			groupBy={(option) => {
				return groupBy ? groupBy(option) : option.location_label;
			}}
			value={savedValues}
			filterSelectedOptions
			multiple={multiple}
			filterOptions={(x) => x}
			renderInput={(params) => (
				<StyledTextInputWrapper>
					<TextField
						variant={isHeaderSearch ? 'outlined' : 'filled'}
						{...params}
						inputProps={{
							...params.inputProps,
							autoComplete: 'search',
							endAdornment: customAdornment || null
						}}
						id='search-field'
						label={searchPlaceholder}
						placeholder={savedValues && savedValues.length ? '' : searchPlaceholder}
					/>
				</StyledTextInputWrapper>
			)}
		/>
	);
};

export default Search;
