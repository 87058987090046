import React from 'react';
import { styled, useTheme } from '@mui/material';

const InputWrapper = styled('div')`
	& .MuiFilledInput-root {
		background: ${(props) => props.theme.palette.background.paper};
		border: 1px solid ${(props) => props.theme.palette.background.paper};
		&:hover {
			border: 1px solid ${(props) => props.theme.palette.primary.main} !important;
		}
	}
`;

export const StyledTextInputWrapper = ({ children }: { children: any }) => {
	const theme = useTheme();

	return <InputWrapper theme={theme}>{children}</InputWrapper>;
};
