import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import './shared/assets/fonts/nhaas-grotesk/index.css';
// eslint-disable-next-line
/* global document, Office, module, require */

const rootElement: HTMLElement | null = document.getElementById('container');
const root = rootElement && createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
	// eslint-disable-next-line
	root?.render(
		<App />
	);
});

if ((module as any).hot) {
	(module as any).hot.accept('./components/App', () => {
		const NextApp = require('./components/App').default;
		root?.render(NextApp);
	});
}
