import { Typography } from '@mui/material';
import { useBoundStore } from '@shared/store/useBoundStore';
import React from 'react';

export const ActionDescription = () => {
	const { action } = useBoundStore((state) => ({
		action: state.action
	}));

	return (
		(action && (
			<Typography sx={{ opacity: '.6', fontSize: '14px', marginTop: '16px' }}>{action?.description}</Typography>
		)) ||
		null
	);
};
