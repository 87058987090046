import React from 'react';
import { Grid, Stack, Chip as muiChip, styled } from '@mui/material';

const Chip = styled(muiChip)(() => ({
	margin: '2px !important',
	height: '18px'
}));

const ChipsArray = ({ chipValues, chipProps, chipAction, isFlat, onDelete, size = 'medium' }: any) => {
	const renderChips = () => {
		const chipArray: any = [];

		if (isFlat) {
			chipValues.forEach((chip: any, key: number) => {
				if (onDelete) {
					chipArray.push(
						<Chip
							size={size}
							color={chipProps?.colors?.[chip]}
							onClick={chipAction && chipAction}
							key={`${chip} - ${key}`}
							label={chip}
							onDelete={(chip) => (onDelete ? onDelete(chip) : null)}
						/>
					);
				} else {
					chipArray.push(
						<Chip
							size={size}
							color={chipProps?.colors?.[chip]}
							onClick={chipAction && chipAction}
							key={`${chip} - ${key}`}
							label={chip}
						/>
					);
				}
			});

			return (
				<Grid item display={'inline-block'}>
					<Stack direction='row' flexWrap={'wrap'} spacing={1}>
						{chipArray}
					</Stack>
				</Grid>
			);
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		const selectedChipsKeys = Object.keys(chipValues);
		if (selectedChipsKeys && selectedChipsKeys.length > 0) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			Object.keys(chipValues).forEach((chipValue: string) => {
				if (chipValues[chipValue]?.values && chipValues[chipValue].values.length > 0) {
					chipValues[chipValue].values.forEach((value: { label: string }, key: number) => {
						const chipLabel: string = chipProps?.isDimension && chipValues[chipValue].group ? 'Slice by' : '';

						chipArray.push(
							<Chip
								color={chipProps?.colors?.[chipValue]}
								sx={{ margin: '4px!important' }}
								onClick={chipAction && chipAction}
								key={`${value?.label} - ${key}`}
								label={`${chipLabel}${chipValues[chipValue].label || chipProps?.getLabel()}: ${value?.label}`}
							/>
						);
					});
				}

				if (chipValues[chipValue]?.group) {
					chipArray.push(
						<Chip
							sx={{ margin: '4px!important' }}
							label={`Slice by ${chipValues[chipValue].label}`}
							onClick={chipAction && chipAction}
						/>
					);
				}
			});

			if (chipAction && chipArray.length) {
				chipArray.push(
					<Chip
						sx={{ margin: '4px!important' }}
						onClick={chipAction && chipAction}
						key={'addchip'}
						label={'+'}
						color='primary'
					/>
				);
			}

			return (
				<Grid item>
					<Stack direction='row' flexWrap={'wrap'} spacing={1}>
						{chipArray}
					</Stack>
				</Grid>
			);
		}

		return null;
	};

	return chipValues ? renderChips() : null;
};

export default ChipsArray;
