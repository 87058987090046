import { Button } from '@mui/material';
import { useBoundStore } from '@shared/store/useBoundStore';
import React, { useState } from 'react';

export const InsertExcelFunction = () => {
	const [isSettingExcelFunction, setIsSettingExcelFunction] = useState(false);
	const { action, actionState } = useBoundStore((state) => ({
		actionState: state.actionState,
		action: state.action
	}));

	const formatQueryState = (queryState: any) => {
		let action_parameters: any = {};
		Object.keys(queryState).forEach((parameter: any) => {
			let paramterLocationSlug = '';
			let populatedParameter: any = {};
			if (Object.keys(queryState[parameter])[0] === 'panels') {
				paramterLocationSlug = parameter;
				const panels: any = [];
				queryState[parameter].panels.forEach((panel: { name: string; label: string }) => panels.push(panel.name));
				populatedParameter = panels;
			} else {
				Object.keys(queryState[parameter]).forEach((subParameter: any) => {
					paramterLocationSlug = parameter;
					populatedParameter = queryState[parameter][subParameter].values.map(
						(valueParameter: any) => valueParameter.value || valueParameter.slug
					);
				});
			}

			if (populatedParameter && populatedParameter?.[0] !== undefined) {
				action_parameters[paramterLocationSlug] = populatedParameter;
			}
		});

		return action_parameters;
	};

	const addFunctionToCell = () => {
		//eslint-disable-next-line
		Excel.run(async (context: any) => {
			const range = context.workbook.getSelectedRange();
			const updatedPayload = formatQueryState(actionState);
			let stringifiedSimplePayload = '';
			const stateKeys = Object.keys(updatedPayload);
			stateKeys.forEach((key, index) => {
				const updatedValues = updatedPayload[key].join(', ');
				const isArray = updatedPayload[key].length > 1;
				const string = `"${key}=${isArray ? '[' : ''}${updatedValues}${isArray ? ']"' : '"'}${
					index !== stateKeys.length - 1 ? ', ' : ''
				}`;
				stringifiedSimplePayload += `${string}`;
			});
			const url = `${action?.service_uri}`;

			range.formulas = [
				[`=CONSUMEREDGE.runQuery("${url}"${stringifiedSimplePayload && ','} ${stringifiedSimplePayload})`]
			];
			await context.sync();
			setIsSettingExcelFunction(false);
		});
	};

	return (
		<Button
			sx={{ marginLeft: '18px' }}
			variant='contained'
			onClick={() => {
				setIsSettingExcelFunction(true);
				addFunctionToCell();
			}}>
			{isSettingExcelFunction ? 'Applying' : 'Insert'}
		</Button>
	);
};
