const components = {
	MuiAutocomplete: {
		defaultProps: {
		},
		styleOverrides: {
			root: {
				borderBottom: '0',
				backgroundColor: 'none'
			}
		}
	},
	MuiFilledInput: {
		styleOverrides: {
			...{
				root: {
					borderBottom: '0 !important',
					borderRadius: '4px'
				}
			}
		}
	},
	MuiTextField: {
		// Set your desired variant as the default here
		defaultProps: {
			variant: 'filled' // Or 'outlined', 'standard', etc.
		},
		styleOverrides: {
			root: {
				boxShadow:
					'0px 4px 34px 0px rgba(77, 77, 77, 0.04), 0px 10px 62px 0px rgba(77, 77, 77, 0.03), 0px 10px 84px 0px rgba(77, 77, 77, 0.02), 0px 20px 100px 0px rgba(77, 77, 77, 0.01)',
				'& > *': {
					borderBottom: 'none'
				},
				'& :before': {
					display: 'none'
				},
				'& .MuiFilledInput-root': {
					borderRadius: 4,
					'&:hover': {
						borderBottom: '1px solid #1346A0 !important'
					}
				}
			}
		}
	},
	MuiButtonBase: {
		defaultProps: {
			disableRipple: true
		}
	},
	MuiLink: {
		defaultProps: {
			underline: 'hover'
		}
	},
	MuiCardHeader: {
		defaultProps: {
			titleTypographyProps: {
				variant: 'h6'
			}
		},
		styleOverrides: {
			action: {
				marginTop: '-4px',
				marginRight: '-4px'
			}
		}
	},
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: '6px',
				boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
				backgroundImage: 'none'
			}
		}
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				backgroundImage: 'none'
			}
		}
	},
	MuiPickersDay: {
		styleOverrides: {
			day: {
				fontWeight: '300'
			}
		}
	},
	MuiPickersYear: {
		styleOverrides: {
			root: {
				height: '64px'
			}
		}
	},
	MuiPickersCalendar: {
		styleOverrides: {
			transitionContainer: {
				marginTop: '6px'
			}
		}
	},
	MuiPickersCalendarHeader: {
		styleOverrides: {
			iconButton: {
				backgroundColor: 'transparent',
				'& > *': {
					backgroundColor: 'transparent'
				}
			},
			switchHeader: {
				marginTop: '2px',
				marginBottom: '4px'
			}
		}
	},
	MuiPickersClock: {
		styleOverrides: {
			container: {
				margin: '32px 0 4px'
			}
		}
	},
	MuiPickersClockNumber: {
		styleOverrides: {
			clockNumber: {
				left: 'calc(50% - 16px)',
				width: '32px',
				height: '32px'
			}
		}
	},
	MuiPickerDTHeader: {
		styleOverrides: {
			dateHeader: {
				'& h4': {
					fontSize: '2.125rem',
					fontWeight: 400
				}
			},
			timeHeader: {
				'& h3': {
					fontSize: '3rem',
					fontWeight: 400
				}
			}
		}
	},
	MuiPickersTimePicker: {
		styleOverrides: {
			hourMinuteLabel: {
				'& h2': {
					fontSize: '3.75rem',
					fontWeight: 300
				}
			}
		}
	},
	MuiPickersToolbar: {
		styleOverrides: {
			toolbar: {
				'& h4': {
					fontSize: '2.125rem',
					fontWeight: 400
				}
			}
		}
	},
	MuiChip: {
		styleOverrides: {
			root: {
				borderRadius: '6px'
			}
		}
	}
};

export default components;
