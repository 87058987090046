import React from 'react';
import { styled } from '@mui/material';

const Spinner = (props: any) => {
	const Spinner = styled('div')`
		animation: spinner-d3wgkg 1s infinite linear;
		border: 9px solid;
		border-color: #dbdcef;
		border-radius: 50%;
		border-right-color: #474bff;
		height: 56px;
		left: calc(50% - 28px);
		position: absolute;
		top: calc(50% - 28px);
		width: 56px;

		@keyframes spinner-d3wgkg {
			to {
				transform: rotate(1turn);
			}
		}
	`;

	return <Spinner {...props} />;
};

export default Spinner;
