import React, { type MouseEvent, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Alert as MuiAlert,
	TextField as MuiTextField,
	styled
} from '@mui/material';
import { spacing } from '@mui/system';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useBoundStore } from '@shared/store/useBoundStore';
import { StyledTextInputWrapper } from '@shared/components/styled-text-input-wrapper/index.styled-text-input-wrapper';
import { NotificationSpinner } from '@entities/notification-spinner/index.notification-spinner';

type FormValues = {
	email: string;
	password: string;
};

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)`
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

const ErrorMessage = (errors: any) => {
	const errorMessage = errors?.email?.message || errors?.password?.message || null;
	if (errorMessage) {
		return (
			<Alert mt={2} mb={3} severity='error'>
				{errorMessage}
			</Alert>
		);
	}

	return null;
};

const LoginForm = () => {
	const [showPassword, setShowPassword] = useState(false);
	const {
		user,
		authRequestLoading,
		authActions: { signIn }
	} = useBoundStore((state) => ({
		user: state.user,
		authRequestLoading: state.authRequestLoading,
		error: state.error,
		authActions: { signIn: state.authActions.signIn }
	}));

	const {
		register,
		handleSubmit,
		setValue,
		trigger,
		reset,
		formState: { errors }
	} = useForm<FormValues>({
		mode: 'onBlur'
	});
	const onSubmit: SubmitHandler<FormValues> = (data) => {
		signIn(data);
	};

	const handleChange = (e: any) => {
		setValue(e.target.name, e.target.value);
		// eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-argument
		trigger(e.target.name);
	};

	useEffect(() => {
		// reset form with user data
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		user && reset(user);
	}, [user]);

	const handleClickShowPassword = () => {
		setShowPassword((show) => !show);
	};

	const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<>
			<ToastContainer />
			{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
			<form noValidate onSubmit={handleSubmit(onSubmit)}>
				{ErrorMessage(errors) && ErrorMessage(errors)}
				<StyledTextInputWrapper>
					<TextField
						placeholder='Email'
						{...register('email', {
							onChange: handleChange,
							required: 'Email is required!',
							validate: {
								maxLength: (v) => v.length <= 50 || 'The email should have at most 50 characters!',
								matchPattern: (v) => /^\S+@\S+$/.test(v) || 'Email address must be a valid address!'
							}
						})}
						fullWidth
					/>
				</StyledTextInputWrapper>
				<FormControl fullWidth sx={{ marginTop: 2 }}>
					<StyledTextInputWrapper>
						<TextField
							data-testid='login-password-field'
							fullWidth
							placeholder='Password'
							id='outlined-adornment-password'
							type={showPassword ? 'text' : 'password'}
							{...register('password')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</StyledTextInputWrapper>
				</FormControl>
				<Button type='submit' sx={{ marginTop: '16px' }} variant='contained' color='primary' data-testid='login-button'>
					{authRequestLoading ? <NotificationSpinner message={'Logging in...'} /> : 'Login'}
				</Button>
				{/* <Button component={Link} to='/reset-password' fullWidth color='primary'>
					Forgot password
				</Button> */}
			</form>
		</>
	);
};

export default LoginForm;
