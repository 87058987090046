import React from 'react';
import { styled, useTheme } from '@mui/material';

const StyledSelect = styled('div')`
	.MuiSelect-select,
	.MuiInputBase-root {
		background: ${(props) => props.theme.palette.background.paper};
		border: 1px solid ${(props) => props.theme.palette.background.paper};
		border-radius: 4px;
		&:hover {
			background-color: rgba(255, 255, 255, 0.13) !important;
		};
		box-shadow: 0px 4px 34px 0px rgba(77, 77, 77, 0.04), 0px 10px 62px 0px rgba(77, 77, 77, 0.03), 0px 10px 84px 0px rgba(77, 77, 77, 0.02), 0px 20px 100px 0px rgba(77, 77, 77, 0.01);
	}
	& .MuiInputBase-root.MuiFilledInput-root {
		&:before {
			border: none;
		}
		&:hover::before {
			border: none;
		}
	}
	.MuiFormControl-root {
		border-radius: 4px;
	}
`;

export const StyledSelectWrapper = ({ children }: { children: any }) => {
	const theme = useTheme();

	return <StyledSelect theme={theme}>{children}</StyledSelect>;
};
