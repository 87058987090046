import React from 'react';
import { Stack, Typography, styled } from '@mui/material';
import Logo from '@shared/assets/full-logo-brand.svg';
import { InsertExcelFunction } from '@features/insert-excel-function/index.insert-excel-function';
import { useBoundStore } from '@shared/store/useBoundStore';

const Spacer = styled('div')`
	margin-top: 16px;
`;

const StyledContainer = styled('div')`
	z-index: 2;
	padding: 19px 24px;
	background: white;
	position: fixed;
	left: 0;
	bottom: 0;
	width: calc(100% - 48px);
`;

export const Footer = () => {
	const { action, selectedCell } = useBoundStore((state) => ({
		action: state.action,
		selectedCell: state.selectedCell
	}));

	return (
		<>
			<div style={{ height: '74px', width: '100%' }} />
			<StyledContainer>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
					<img style={{ maxHeight: '48px', color: '#000' }} src={Logo} />
					<Stack direction={'row'} sx={{ textAlign: 'right' }} alignItems={'center'}>
						<Typography fontWeight={'bold'} color={'#40D6B8'}>
							{selectedCell}
						</Typography>
						{action?.name && (
							<>
								<Spacer />
								<InsertExcelFunction />
							</>
						)}
					</Stack>
				</Stack>
			</StyledContainer>
		</>
	);
};
