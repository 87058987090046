import '@mui/lab/themeAugmentation';

import { createTheme as createMuiTheme } from '@mui/material/styles';
import variants from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';

import shadows from './shadows';
import { type THEMES } from '@shared/utils/index';

const createTheme = (name: keyof typeof THEMES) => {
	let themeConfig = variants.find((variant) => variant.name === name);

	if (!themeConfig) {
		console.warn(new Error(`The theme ${name} is not valid`));
		themeConfig = variants[0];
	}

	return createMuiTheme(
		{
			spacing: 4,
			breakpoints,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			components,
			typography: typography as any,
			shadows: shadows as any,
			palette: themeConfig.palette as any,
			sidebar: themeConfig.sidebar as any,
			tile: themeConfig.tile
		},
		{
			name: themeConfig.name,
			header: themeConfig.header,
			footer: themeConfig.footer,
			sidebar: themeConfig.sidebar,
			tile: themeConfig.tile,
			customButtons: themeConfig.customButtons
		}
	);
};

export default createTheme;
