/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { type StateCreator } from 'zustand';
import { type AuthSliceInterface, TabRouterSliceInterface } from './store-types';
import { httpRequest } from '@shared/api/http-request';
import api from '@shared/api/config';

const { authApi } = api;

export const createAuthSlice: StateCreator<
	AuthSliceInterface & TabRouterSliceInterface,
	[['zustand/immer', never], ['zustand/devtools', never]],
	[],
	AuthSliceInterface
> = (set, get) => ({
	user: null,
	isAuthenticated: false,
	authRequestLoading: false,
	error: false,
	createUserError: false,
	errorMessage: '',
	authActions: {
		setUser: (user) => {
			localStorage.setItem('user', JSON.stringify({ ...user }));
			set(() => ({ user, isAuthenticated: user !== null }));
		},
		signIn: (signInData) => {
			set(() => ({ authRequestLoading: true, error: false }));
			const { email, password } = signInData;
			httpRequest<any>({
				method: 'post',
				url: `${authApi.url}/users/login`,
				data: {
					data: {
						email,
						password
					}
				},
				resolveResponse: (response) => {
					const fullUser = { ...response[0].attributes, id: response[0].id };
					get().tabRouterActions.setSelectedTab('action-execution');
					get().authActions.setUser(fullUser);
				},
				handleError: (error) => {
					set(() => ({ authRequestLoading: false, error }));
				},
				resolveAfterRequest: () => {
					set(() => ({ authRequestLoading: false }));
				}
			});
		},
		signOut: () => {
			localStorage.setItem('user', '');
			set(() => ({ user: null, isAuthenticated: false }));
		},
		signUp: (data) => {
			const { email, password, displayName, name } = data;
			set(() => ({ authRequestLoading: true, createUserError: undefined }));
			httpRequest({
				method: 'post',
				url: `${authApi.url}/users`,
				data: {
					data: {
						email,
						password,
						display_name: displayName,
						name
					}
				},
				resolveResponse: (response: any) => {
					get().authActions.signIn({ ...response[0].attributes, password: data.password });
				},
				handleError: (error) => {
					set(() => ({
						createUserError: error.response.data,
						authRequestLoading: false
					}));
				}
			});
		},
		setError: () => {
			return false;
		},
		setCreateUserError: () => {
			return false;
		},
		verifyToken: () => {
			let user: any = localStorage.getItem('user');
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/no-unsafe-argument
			user ? (user = JSON.parse(user)) : null;
			httpRequest({
				method: 'post',
				data: {},
				url: `${authApi.url}/verifyToken`,
				resolveResponse: () => {
					get().tabRouterActions.setSelectedTab('action-execution');

					get().authActions.setUser(user);
				},
				// eslint-disable-next-line
				handleError: (_error: { response: { data: any; status: any; headers: any } }) => {
					get().authActions.setUser(null);
					get().tabRouterActions.setSelectedTab('login');
					// if (!window.location.href.includes('login') || !window.location.href.includes('forgot-password')) {
					//   localStorage.setItem('user', '');
					//   !window.location.href.includes('login') && window.location.replace('/login');
					// }
				}
			});
		},
		initializeAuth: () => {
			try {
				let user: any = localStorage.getItem('user');
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/no-unsafe-argument
				user ? (user = JSON.parse(user)) : null;
				// eslint-disable-next-line
				if (user?.token && user.id) {
					get().authActions.verifyToken();
				} else {
					get().authActions.setUser(null);
				}
			} catch (err) {
				console.error(err);
				get().authActions.setUser(null);
			}
		},
		setDefaultDashboard: (dashboardId) => {
			set(() => ({ authRequestLoading: true }));
			httpRequest<any>({
				method: 'patch',
				url: `${authApi.url}/users/${get().user.id}`,
				data: {
					data: { default_dashboard: dashboardId }
				},
				resolveResponse: (response) => {
					set(() => ({ authRequestLoading: false }));
					const fullUser = {
						...get().user,
						...response[0].attributes,
						id: response[0].id
					};
					get().authActions.updateUser(fullUser);
				},
				handleError: (error) => {
					set(() => ({ authRequestLoading: false }));
					console.error('Failed to update user', error);
				}
			});
		},
		updateUser: (userData) => {
			set(() => ({ user: userData }));
			localStorage.setItem('user', JSON.stringify({ ...get().user, ...userData.user }));
		}
	}
});
