import { useBoundStore } from '@shared/store/useBoundStore';
import { useEffect } from 'react';

export function WithAuth({ children }: { children: any }) {
	const {
		user,
		authActions: { initializeAuth }
	} = useBoundStore((state) => ({
		user: state.user,
		authActions: { initializeAuth: state.authActions.initializeAuth }
	}));

	useEffect(() => {
		initializeAuth();
	}, []);

	return children;
}
