import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';
import { StyledTextInputWrapper } from '@shared/components/styled-text-input-wrapper/index.styled-text-input-wrapper';
import { httpRequest } from '@shared/api/http-request';
import api from '@shared/api/config';
import { DefaultFields, Panel } from '@shared/models/types';

type PanelRecord = { value: string; label: string };
type NormalizedPanel = { name: string; label: string };

const SelectPanels = ({
	selectedPanels,
	handleQueryInput,
	parameterName,
	parameterLabel,
	fieldType,
	fieldDefaultsList,
	restrictedToValuesList
}: {
	selectedPanels?: { [k: string]: Panel[] };
	handleQueryInput: (arg: any) => void;
	parameterName: string;
	parameterLabel: string;
	fieldType: string;
	fieldDefaultsList: DefaultFields[];
	restrictedToValuesList: any;
}) => {
	const { librarianApi } = api;
	const [panels, setPanels] = useState([]);

	const normalizePanels = (panelList: any) => {
		let panelReturn: NormalizedPanel[] | [] = [];
		if (panelList.length > 0) {
			panelReturn = panelList.map((panel: PanelRecord) => {
				return {
					name: panel.value,
					label: panel.label
				};
			});
		}

		return panelReturn;
	};

	const defaultPanels = normalizePanels(fieldDefaultsList);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		httpRequest({
			method: 'get',
			url: `${librarianApi.url}/panels`,
			resolveResponse: (response: any) => {
				let finalPanels = response;
				let restrictedPanels: any = [];
				if (restrictedToValuesList.length > 0) {
					restrictedPanels = normalizePanels(restrictedToValuesList);
				}

				if (restrictedPanels.length) {
					finalPanels = response.filter((panel: any) =>
						restrictedPanels.some((restrictedPanel: any) => panel.name === restrictedPanel.name)
					);
				}

				setPanels(
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					finalPanels
				);
				if (defaultPanels && defaultPanels.length > 0) {
					const defaultSelection = {
						[fieldType]: defaultPanels
					};
					handleQueryInput?.({ arg: defaultSelection, queryDataKey: parameterName });
				}
			},
			handleError: (error) => {
				console.error('Cannot get panels', error);
			}
		});
	}, []);

	const handleChange = ({ values }: { values: any }) => {
		let panelsValues = structuredClone(selectedPanels);
		if (panelsValues) {
			panelsValues[fieldType] = values;
		} else {
			panelsValues = {
				[fieldType]: values
			};
		}

		//TODO: fix lol
		// if (fieldDefaultsList && fieldDefaultsList.length > 0) {
		// 	panelsValues[fieldType] = [...defaultPanels, ...panelsValues[fieldType]]
		// }
		handleQueryInput?.({ arg: panelsValues, queryDataKey: parameterName });
	};

	const renderTagsOverride = (tagValue: any, getTagProps: any) => {
		return tagValue.map((option: any, index: any, key: any) => {
			return (
				<Chip
					key={key}
					label={option.label}
					{...getTagProps({ index })}
					disabled={defaultPanels.filter((defaultValue: any) => defaultValue?.name === option.name)?.length > 0}
				/>
			);
		});
	};

	const renderDatasets = () => {
		return (
			<FormControl sx={{ width: '100%' }}>
				<Autocomplete
					id='multiple-chip'
					options={panels ?? []}
					multiple
					value={selectedPanels?.panels ?? []}
					onChange={(e, values) => {
						handleChange({ values });
					}}
					getOptionLabel={(option: any) => {
						return option.label;
					}}
					isOptionEqualToValue={(option, value) => option.name === value.name}
					renderTags={(tagValue, getTagProps) => {
						return renderTagsOverride(tagValue, getTagProps);
					}}
					renderInput={(params) => {
						return (
							<StyledTextInputWrapper>
								<TextField
									{...params}
									label={parameterLabel}
									placeholder={selectedPanels?.panels ? '' : `Select ${parameterLabel}`}
								/>
							</StyledTextInputWrapper>
						);
					}}
				/>
			</FormControl>
		);
	};

	return renderDatasets();
};

export default SelectPanels;
