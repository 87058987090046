/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { type StateCreator } from 'zustand';
import { type AuthSliceInterface, type CEActionSliceInterface } from './store-types';
import { Action } from '@shared/models/types';

export const createActionSlice: StateCreator<
	AuthSliceInterface & CEActionSliceInterface,
	[['zustand/immer', never], ['zustand/devtools', never]],
	[],
	CEActionSliceInterface
> = (set) => ({
	action: undefined,
	actionState: {},
	ceActionActions: {
		setAction: (action: Action) => {
			set(() => ({ action }));
		},
		updateAction: (actionUpdate: any, actionKey: string) => {
			set((state) => {
				state.actionState[actionKey] = actionUpdate;
			});
		},
		resetActionState: () => {
			set(() => ({ actionState: {}, action: null }));
		}
	}
});
