import api from '@shared/api/config';
import { httpRequest } from '@shared/api/http-request';
import { Action, ActionParameter, ActionParameterValue } from '@shared/models/types';
import { useBoundStore } from '@shared/store/useBoundStore';
import { useActionsList } from '@widgets/actions-list/queries/index.useActionsList';
import { useState } from 'react';

type ActionParameterSearchValues = {
	locationSlug: string;
	valueSlug?: string;
	parameterName: string;
	type?: string;
	selectedPanels?: string[];
};

type Panel = {
	name: string;
	label: string;
};

export const useHandleFormPopulationFromExcelFormula = () => {
	const [handlerLoading, setHandlerLoading] = useState(false);
	const { actions } = useActionsList();
	const { setAction, cellActionState, updateAction } = useBoundStore((state) => ({
		setAction: state.ceActionActions.setAction,
		cellActionState: state.cellActionState,
		updateAction: state.ceActionActions.updateAction
	}));

	const { jtubesRoot, librarianApi } = api;

	const searchGrabbedValues = ({ locationSlug, valueSlug, parameterName }: ActionParameterSearchValues) => {
		httpRequest<any>({
			method: 'post',
			url: `${jtubesRoot.url}/search`,
			hasNestedDataResponse: false,
			data: {
				data: {
					query: '',
					filters: {
						location_slug: [locationSlug],
						slug: valueSlug
					},
					exclusions: {}
				}
			},
			handleError: () => {
				console.error('no results');
			},
			resolveResponse: (response) => {
				if (response && response.data.length > 0) {
					const { data } = response;

					const returnedData = data.map((value: { slug: string; label: string }) => {
						return { value: value.slug, label: locationSlug === 'symbol' ? value.slug : value.label };
					});
					const populatedState: any = { [locationSlug]: { values: returnedData } };
					updateAction(populatedState, parameterName);
				}
			}
		});
	};

	const searchGrabbedPanels = ({ locationSlug, parameterName, selectedPanels }: ActionParameterSearchValues) => {
		httpRequest({
			method: 'get',
			url: `${librarianApi.url}/panels`,
			resolveResponse: (response: any) => {
				const finalPanelsArray = response.filter((panel: Panel) => {
					if (selectedPanels?.includes(panel.name)) {
						return { value: panel.name, label: panel.label };
					}
				});
				const populatedState = { [locationSlug]: finalPanelsArray };
				updateAction(populatedState, parameterName);
			},
			handleError: (error) => {
				console.error('Cannot get panels', error);
			}
		});
	};

	const populateForm = (foundAction: Action) => {
		setAction(foundAction);
		foundAction?.action_parameters?.forEach?.((parameter: ActionParameter) => {
			const parameterName = parameter.name;
			const actionValue: ActionParameterValue = cellActionState.payload[parameterName];
			let locationSlug;
			if (parameter?.values?.name) {
				const { name } = parameter.values;
				locationSlug = name;
			} else {
				locationSlug = parameter.type;
			}

			if (parameter.type === 'dimensions' || parameter.type === 'symbol' || parameter.type === 'segment') {
				searchGrabbedValues({
					valueSlug: actionValue,
					locationSlug,
					type: parameter.type,
					parameterName
				});
			} else if (parameter.type === 'panels') {
				searchGrabbedPanels({ selectedPanels: actionValue, parameterName, locationSlug });
			} else if (parameter.type === 'custom') {
				const foundValue = parameter?.values?.values?.filter?.((customValueSet: { slug: string }) =>
					actionValue?.includes?.(customValueSet.slug)
				);
				if (foundValue) {
					const populatedState: any = { [parameterName]: { values: foundValue } };
					updateAction(populatedState, parameterName);
				}
			} else if (parameter.type === 'number') {
				const populatedState: any = { [parameterName]: { values: [{ value: actionValue?.[0] }] } };
				updateAction(populatedState, parameterName);
			}
		});
		setHandlerLoading(false);
	};

	const getActionFromFormulaBar = () => {
		setHandlerLoading(true);
		const foundAction: Action[] = actions.filter(
			(actionListItem: Action) => actionListItem?.service_uri === cellActionState?.url
		);
		if (foundAction?.length > 0) {
			populateForm(foundAction[0]);
		} else {
			setHandlerLoading(false);
		}
	};

	return { getActionFromFormulaBar, handlerLoading };
};
