import React from 'react';
import { Stack } from '@mui/material';
import Search from '@features/search/index.search';

export const SelectSymbol = ({ selectedSymbols, handleQueryInput, parameterName, parameterLabel, fieldType }: any) => {
	const setSelectedSymbol = (values: any) => {
		let previousSelection = structuredClone(selectedSymbols);
		if (previousSelection && previousSelection.length > 0) {
			previousSelection[fieldType].values = values;
		} else {
			previousSelection = {
				[fieldType]: { values }
			};
		}

		handleQueryInput({ arg: previousSelection, queryDataKey: parameterName });
	};

	return (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<Search
				params={{
					filters: {
						location_slug: ['symbol']
					},
					exclusions: {}
				}}
				onSearchOptionSelect={(value: any) => {
					setSelectedSymbol(value);
				}}
				groupBy={(e: any) => e?.type_label}
				savedValues={selectedSymbols?.[fieldType]?.values ?? []}
				optionLabel={(e: any) => e?.slug || e?.value}
				searchPlaceholder={parameterLabel}
				limit={20}
				searchImmediately={true}
			/>
		</Stack>
	);
};
