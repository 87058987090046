// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./NHaasGroteskTXPro-55Rg.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./NHaasGroteskTXPro-65Md.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{\n\tfont-family: 'nHaas';\n\tsrc: local('NHaasGroteskTXPro-55Rg'),\n\turl(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n  font-weight: bold;\n}\n\n@font-face{\n\tfont-family: 'nHaas';\n\tsrc: local('NHaasGroteskTXPro-65Rg'),\n\turl(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n  font-weight: bolder;\n}\n\n", "",{"version":3,"sources":["webpack://./src/taskpane/shared/assets/fonts/nhaas-grotesk/index.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB;2DACsD;EACrD,iBAAiB;AACnB;;AAEA;CACC,oBAAoB;CACpB;2DACsD;EACrD,mBAAmB;AACrB","sourcesContent":["@font-face{\n\tfont-family: 'nHaas';\n\tsrc: local('NHaasGroteskTXPro-55Rg'),\n\turl('./NHaasGroteskTXPro-55Rg.otf') format('opentype');\n  font-weight: bold;\n}\n\n@font-face{\n\tfont-family: 'nHaas';\n\tsrc: local('NHaasGroteskTXPro-65Rg'),\n\turl('./NHaasGroteskTXPro-65Md.otf') format('opentype');\n  font-weight: bolder;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
